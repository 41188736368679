import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { ApplicationRoutes } from 'containers/System/routes';
import bannerLR from './images/bannerLR.jpg';
import banner from './images/banner.jpg';
import bannerRes from './images/banner-res.jpg';
import bannerResLR from './images/banner-resLR.jpg';
const handleTab1Click = () => {
    const tab2Header = document.getElementById('tab2_header');
    const tab2Content = document.getElementById('tab2_content');
    if (tab2Header) {
        tab2Header.classList.remove('highlight');
    }
    if (tab2Content) {
        tab2Content.classList.remove('card-tab-active');
    }
    const tab1Header = document.getElementById('tab1_header');
    const tab1Content = document.getElementById('tab1_content');
    if (tab1Header) {
        tab1Header.classList.add('highlight');
    }
    if (tab1Content) {
        tab1Content.classList.add('card-tab-active');
    }
};
const handleTab2Click = () => {
    const tab1Header = document.getElementById('tab1_header');
    const tab1Content = document.getElementById('tab1_content');
    if (tab1Header) {
        tab1Header.classList.remove('highlight');
    }
    if (tab1Content) {
        tab1Content.classList.remove('card-tab-active');
    }
    const tab2Header = document.getElementById('tab2_header');
    const tab2Content = document.getElementById('tab2_content');
    if (tab2Header) {
        tab2Header.classList.add('highlight');
    }
    if (tab2Content) {
        tab2Content.classList.add('card-tab-active');
    }
};
const Banner = ({ scrollPosition }) => (React.createElement("div", { className: "container-fluid no-pad banner-por" },
    React.createElement("div", { className: "banner-bg" },
        React.createElement("div", { style: {
                position: 'absolute', width: '37%', marginLeft: '5%', marginRight: '5%', marginTop: '5%', minWidth: '500px',
            } },
            React.createElement("div", { className: "card" },
                React.createElement("div", { className: "card-header" },
                    React.createElement("div", { id: "tab1_header", className: "highlight", onClick: handleTab1Click }, "Appearance Coverage"),
                    React.createElement("div", { id: "tab2_header", onClick: handleTab2Click }, "Freelance Work")),
                React.createElement("div", { id: "tab1_content", className: "card-content card-tab-content card-tab-active" },
                    React.createElement("h2", null, "Schedule Court Appearance Coverage"),
                    React.createElement("p", { style: { marginBottom: '20px', marginLeft: '20px', marginRight: '20px' } }, "Get more done with the right appearance attorney."),
                    React.createElement("p", { style: { marginLeft: '20px', marginRight: '20px' } }, "Whether it's a routine or emergency hearing or deposition, Attorneys in Motion is where law firms go to get appearance coverage."),
                    React.createElement("a", { href: ApplicationRoutes.clientSignUp, className: "card-button" }, "Learn More")),
                React.createElement("div", { id: "tab2_content", className: "card-content card-tab-content" },
                    React.createElement("h2", null, "Hire a Contract Attorney For Project-Based Legal Writing"),
                    React.createElement("p", { style: { marginBottom: '20px', marginLeft: '20px', marginRight: '20px' } }, "Our project-based legal work platform gives you the tools you need to scale and grow your law firm."),
                    React.createElement("p", { style: { marginLeft: '20px', marginRight: '20px' } }, "Hire an attorney per project or per hour for long-term, ongoing work."),
                    React.createElement("a", { href: "https://lawbeez.com", className: "card-button", target: "_blank", rel: "noreferrer" }, "Learn More")))),
        React.createElement(LazyLoadImage, { alt: "Local Counsel Attorneys", title: "Local Counsel Attorneys", scrollPosition: scrollPosition, src: banner, placeholderSrc: bannerLR })),
    React.createElement("div", { className: "banner-bg-res" },
        React.createElement(LazyLoadImage, { alt: "Appearance Attorney Services", title: "Appearance Attorney Services", scrollPosition: scrollPosition, src: bannerRes, placeholderSrc: bannerResLR }))));
export default trackWindowScroll(Banner);
