import { ApplicationRoutes } from 'containers/System/routes';
export const AUTH_VARIABLE = 'auth';
export const CONTENT_TYPE = 'application/json';
export const LOGIN_PATH = ApplicationRoutes.login.toString();
export const GET_REFRESH_TOKEN_API = '/refresh';
export const API_PATHES = {
    push: {
        list: '/v1/push-event',
        markReadList: '/v1/push-event/mark-as-read',
        markReadAll: '/v1/push-event/mark-as-read/all',
        deleteList: '/v1/push-event',
        deleteAll: '/v1/push-event/all',
        unreadCount: '/v1/push-event/count-unread',
    },
    admin: {
        invoice: '/v1/admin/invoice',
        invoiceReportById: '/v1/admin/invoice/{id}',
        invoiceReportExportById: '/v1/admin/invoice/{id}/export',
        invoiceReport: '/v1/admin/invoice/report',
        removeAAFromAppearance: '/v1/admin/appearance/{id}/remove-attorney',
        attorneysOfAppearance: '/v1/admin/appearance/{id}/attorneys',
        appearanceNode: '/v1/admin/appearance/{id}/note',
        notifications: '/v1/admin/notifications',
        emailSettings: '/v1/admin/email/settings',
        appearanceById: '/v1/admin/appearance/{id}',
        attorneyDisplayName: '/v1/admin/appearance/{id}/attorney/display',
        exportClients: '/v1/admin/client/export',
        exportAA: '/v1/admin/attorney/export',
        allAppearances: '/v1/admin/appearance/manageList',
        paymentDueAppearances: '/v1/admin/appearance/payment-due',
        logs: '/v1/admin/logs',
        message: '/v1/admin/message',
        locationCourt: '/v1/admin/location/court',
        locationCounty: '/v1/admin/location/county',
        locationCourtById: '/v1/admin/location/court/{id}',
        locationCourtNotify: '/v1/admin/location/court/notify-new',
        locationFees: '/v1/admin/location/fees',
        attorneys: '/v1/admin/attorney',
        allAttorneys: '/v1/admin/appearance/{id}/all-attorneys',
        mailToAttorneys: '/v1/admin/appearance/{id}/mail/attorneys',
        clients: '/v1/admin/client',
        clientDetails: '/v1/admin/client/{id}',
        attorneyDetails: '/v1/admin/attorney/{id}',
        userNote: '/v1/admin/user/note',
        userActivate: '/v1/admin/user/activate',
        userDeActivate: '/v1/admin/user/deactivate',
        fee: {
            nonStandard: '/v1/admin/non-standard-fee',
        },
        coupon: '/v1/admin/coupon',
        couponById: '/v1/admin/coupon/{id}',
        standardFee: '/v1/admin/standard-fee',
        standardFeeById: '/v1/admin/standard-fee/{id}',
        paymentAttorneyFee: '/v1/admin/payment/attorney/fee',
        paymentClientFee: '/v1/admin/payment/client/fee',
        locationCountyById: '/v1/admin/location/county/{id}',
        locationState: '/v1/admin/location/state',
        locationStateById: '/v1/admin/location/state/{id}',
        hrEmail: '/v1/admin/appearance/{id}/mail/higher-rate',
        chargeClient: '/v1/admin/appearance/{id}/charge',
        payment: '/v1/admin/payment',
        paymentHistory: '/v1/admin/payment/history',
        paymentExport: '/v1/admin/payment/export',
        paymentHistoryExport: '/v1/admin/payment/history/export',
        emailTemplateList: '/v1/admin/email-template',
        emailTemplateSave: '/v1/admin/email-template',
        emailTemplateItem: '/v1/admin/email-template/{id}',
    },
    auth: {
        login: '/v1/auth/login',
        logout: '/v1/auth/logout',
        refresh: '/v1/auth/token/refresh',
        check: '/v1/auth/token/check',
        signUpClient: '/v1/auth/signup/client',
        signUpAttorney: '/v1/auth/signup/attorney',
    },
    attAppearances: {
        available: '/v1/attorney/appearance/available',
        accepted: '/v1/attorney/appearance/accepted',
        submit: '/v1/attorney/appearance/submit',
        fetch: '/v1/attorney/appearance/',
        fetchById: '/v1/attorney/appearance/{id}',
        results: '/v1/attorney/appearance/{id}/results',
        mailAccept: '/v1/appearance/accept/{attorneyId}/{appearanceId}/{clientId}',
        mailReject: '/v1/appearance/reject/{attorneyId}/{appearanceId}/{clientId}',
        cancel: '/v1/attorney/appearance/{id}/cancel',
        accept: '/v1/attorney/appearance/{id}/accept',
        statusById: '/v1/attorney/appearance/status/{id}',
    },
    clientAppearances: {
        results: '/v1/client/appearance/results',
        manage: '/v1/client/appearance/manageList',
        invoice: '/v1/client/appearance/invoice',
        invoiceById: '/v1/client/invoice/{invoiceId}',
        appearanceById: '/v1/client/appearance/{id}',
        invoicePdfById: '/v1/client/invoice/{invoiceId}/pdf',
        invoiceRangePdf: '/v1/client/invoice/range/pdf?from={from}&to={to}',
        invoiceRangeXls: '/v1/client/invoice/range/excel?from={from}&to={to}',
        schedule: '/v1/client/appearance/schedule',
        fee: '/v1/client/appearance/calculateFees',
        search: '/v1/client/appearance/attorney/search',
        files: '/v1/client/appearance/{id}/files',
        cancel: '/v1/client/appearance/{id}/cancel',
    },
    templates: {
        create: '/v1/client/template/create',
        update: '/v1/client/template/{id}',
        manage: '/v1/client/template/manageTemplateList',
        templateById: '/v1/client/template/{id}',
        createFromAppearance: '/v1/client/template/clone/{id}',
        delete: '/v1/client/template/delete/{id}',
    },
    messages: {
        message: '/v1/message',
        messageById: '/v1/message/{id}',
        unreadList: '/v1/message/unread/count',
        archive: '/v1/admin/message/archive',
    },
    mail: {
        contact: '/v1/mail/contact',
    },
    promoValidate: '/v1/client/promocode/validate',
    files: {
        downloadByUUID: '/v1/file/{fileUid}/download',
        downloadAllByAppearanceIdByAA: '/v1/attorney/appearance/{id}/files',
        downloadAllByAppearanceIdByClient: '/v1/client/appearance/{id}/files/download',
        downloadAllByAppearanceIdByAdmin: '/v1/admin/appearance/{id}/files/download',
        upload: '/v1/file/upload',
        appearanceFilesById: '/v1/appearance/{id}/files',
    },
    firebase: {
        token: '/v1/firebase/token',
    },
    account: {
        password: '/v1/account/password',
        clAccount: '/v1/client/account',
        clCard: '/v1/client/account/card',
        clEmails: '/v1/client/account/emails',
        attAccount: '/v1/attorney/account',
        attManaged: '/v1/attorney/account/managed',
        attManagedCourt: '/v1/attorney/account/managed/court',
        attManagedState: '/v1/attorney/account/managed/state',
        attManagedCourtById: '/v1/attorney/account/managed/court/{id}',
        attManagedCountyById: '/v1/attorney/account/managed/county/{id}',
        attManagedStateById: '/v1/attorney/account/managed/state/{id}',
    },
    attCaseTypes: {
        current: '/v1/attorney/caseTypes/current',
        available: '/v1/attorney/caseTypes​/available',
        caseTypes: '/v1/attorney/caseTypes',
    },
    dictionaries: {
        appearanceType: '/v1/appearanceType/list',
    },
    places: {
        appearanceFee: '/v1/appearanceFeeLocation/calculateFee',
        stateList: '/v1/state/list',
        countyList: '/v1/county/list',
        courtList: '/v1/court/list',
        service: '/v1/service',
        serviceRate: '/v1/service/rate',
    },
    password: {
        forgot: '/v1/password/forgot',
        reset: '/v1/password/reset/{hash}',
    },
    appearance: {
        files: '/v1/appearance/{id}/files',
    },
    mobile: '/v1/mobile',
};
